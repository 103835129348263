import axios from 'axios'
import { showAlert, showAlertMessage, reOpenSignup } from '../components/login'
import { setToken, checkAuth } from './authControl'
import { apiBaseURL } from './utils'
import { logout, loginUser } from './login'
import { contactUs, verify, verify2 } from './contact'

const registerUser = () => {
   position = '#alertModal'
   showAlert(position)

   const form = document.querySelector('.signup-container form')
   const inputs = form.querySelectorAll('input')

   let [username, name, email, phone, password, agreement] = inputs

   const setError = (input, message) => {
      let [username, name, email, phone, password] = inputs

      switch (input) {
         case 'username':
            input = username
            break;
         case 'surname':
            input = name
            break;
         case 'firstname':
            input = name
            break;
         case 'email':
            input = email
            break;
         case 'phone':
            input = phone
            break;
         case 'password':
            input = password
            break;
         default:
            break;
      }
      input.value = ''
      input.placeholder = message
      const Style = input.style
      Style.color = 'red'
      Style.border = 'solid 1px red'
   }

   const setAgreementError = () => {
      let [username, name, email, phone, password, agreement] = inputs
      const label = agreement.parentElement

      const Style = label.style
      Style.border = 'solid 1px red'
   }

   const clearAgreementError = () => {
      let [username, name, email, phone, password, agreement] = inputs
      const label = agreement.parentElement

      const Style = label.style
      Style.border = 'none'
   }

   const clearErrors = () => {
      clearAgreementError()
      let [username, name, email, phone, password] = inputs
      let inputArray = [username, name, email, phone, password]

      inputArray.forEach(el => {
         const Style = el.style
         Style.color = 'white'
         Style.border = 'none'
      })

      username.placeholder = 'Username'
      name.placeholder = 'Surname, Firstname'
      email.placeholder = 'Email'
      phone.placeholder = 'Phone'
      password.placeholder = 'Password'
   }

   const clearInputs = () => {
      clearErrors()
      inputs.forEach(el => {
         el.value = '';
      })
   }

   username = username.value
   name = name.value
   email = email.value
   phone = phone.value
   password = password.value
   agreement = agreement.checked

   let names = name.split(', ')
   let surname = names[0]
   let firstname = names[1]

   if (agreement) {
      clearErrors()
      axios.post(`${apiBaseURL}/accounts/register`, {
         username,
         surname,
         firstname,
         email,
         phone,
         password
      }, { withCredentials: true })
         .then((res) => {
            clearInputs()
            showAlertMessage(position, 'Success! Your account has been created')
            setToken(res.data.accessToken)
            checkAuth()
         })
         .catch((err) => {
            position = '#alertModal'
            checkAuth()
            if (err.response) {
               reOpenSignup(position)
               let error = err.response.data.error
               let status = error.status
               let errMessage = error.message
               let errBreak = errMessage.split('"')
               errInput = errBreak[1]
               if (status == 422) {
                  setError(errInput, `${errInput} is invalid`)
               } else if (status == 409) {
                  let c = errBreak[2].split(' ')
                  c = c[1]
                  setError(errInput, `"${c}" is already in use`)
                  console.log(errMessage)
               } else {
                  showAlert(position)
                  showAlertMessage(position, 'Unexpected error. Try again!')
               }
            } else if (err.request) {
               showAlert(position)
               showAlertMessage(position, 'Failed to connect to server')
            } else {
               showAlert(position)
               showAlertMessage(position, 'Unexpected error. Try again!')
            }
         })
   } else {
      reOpenSignup(position)
      setAgreementError()
   }
}

// window.onload = () => {
//    checkAuth()
// }

const signupSubmit = document.querySelector('.signup-container button')
signupSubmit.onclick = () => {
   registerUser()
}

const logoutBtn = document.querySelector('#logoutBtn')
logoutBtn.onclick = () => {
   logout()
}

const loginSubmit = document.querySelector('.login-container button')
loginSubmit.onclick = () => {
   loginUser()
   // console.log("Here now")

}

const contact = document.querySelector('.footer-contact-modal button')
contact.onclick = () => {
   contactUs()
}

const verifySubmit = document.querySelector('.custom-duty-form button')
verifySubmit.onclick = () => {
   verify()
}

const serviceVerifySubmit = document.querySelector('.service-verify button')
serviceVerifySubmit.onclick = () => {
   verify2()
}
