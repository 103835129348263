import axios from 'axios'
import { apiBaseURL } from './utils'
import { setAuth, checkAuth, setToken } from './authControl'
import { showAlert, showAlertMessage } from '../components/login'


export const loginUser = () => {
   const position = '#alertModal'
   showAlert(position)

   const form = document.querySelector('.login-container form')
   const inputs = form.querySelectorAll('input')

   let [email, password] = inputs
   email = email.value
   password = password.value

   const clearInputs = () =>{
      inputs.forEach(input => {
         input.value = ''
      })
   }

   axios.post(`${apiBaseURL}/accounts/login`, {
      email,
      password }, { withCredentials: true })
      .then((res) => {
         clearInputs()
         showAlertMessage(position, 'Login Succesful')
         setToken(res.data.accessToken)
         setAuth(true)
         checkAuth()
      })
      .catch((err) => {
         if (err.response) {
            let error = err.response.data.error
            let errMessage = error.message
            showAlertMessage(position, errMessage)
         } else if (err.request) {
            showAlertMessage(position, 'Connection to the server was lost')
         } else {
            showAlertMessage(position, 'Unexpected error')
            console.log(err)
         }
      })
}

export const logout = () => {
   position = '#alertModal'
   showAlert(position, true)

   axios.delete(`${apiBaseURL}/accounts/logout`, { withCredentials: true }, {})
      .then((res) => {
         setAuth(false)
         checkAuth()
         location.href = '/';
      })
      .catch((err) => {
         setAuth(false)
         state = false
         if (err.response) {
            showAlertMessage(position, 'Something went wrong')
         } else if (err.request) {
            showAlertMessage(position, 'Connection to the server was lost')
         } else {
            showAlertMessage(position, 'Unexpected error')
         }
      })
}