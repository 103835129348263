import axios from 'axios'
import { apiBaseURL } from './utils'
import { showAlert, showAlert2, showAlertMessage, showAlertMessageAndClose } from '../components/login'

let position = '#alertModal'

export const contactUs = () => {
    showAlert(position)

    const form = document.querySelector('.footer-contact-modal form')
    const inputs = form.querySelectorAll('input')
    const subject = form.querySelector('select')
    const message = form.querySelector('textarea')

    let [name, email] = inputs
    const contactData = {
        email: email.value,
        name: name.value,
        subject: subject.value,
        message: message.value
    }

   console.log("In Contact Us Now: ", contactData)

   try {

        if (contactData.email == "" || contactData.name == "" || contactData.subject == "" || contactData.message == "") {
            // console.log("In throw")
            throw("All input fields are required")
        }


        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(!contactData.email.match(mailformat)) {
            throw("Your email address is incorrect. Kindly check again!")
        }

        axios.post(`${apiBaseURL}/contact`, contactData)
            .then((res) => {
                // clearInputs()
                showAlertMessageAndClose(position, 'Message sent Succesfully')
            })
            .catch((err) => {
                if (err.response) {
                    showAlertMessage(position, err.response.data.message)
            } else {
                    showAlertMessage(position, 'Unexpected error')
                }
        })
   } catch (e) {
       console.log(e)
        showAlertMessage(position, e)
   }
}

export const verify = () => {
    showAlert(position)

    const form = document.querySelector('.custom-duty-form form')
    const inputs = form.querySelectorAll('input')
    const office = form.querySelector('select')


    let [name, email, phone, manifest, cnumber, date, agreement] = inputs

    const payload = {
        email: email.value,
        name: name.value,
        phone: phone.value,
        manifest: manifest.value,
        cnumber: parseInt(cnumber.value),
        date: date.value,
        office: office.value,
        agreement: agreement.checked
    }


    try {
        if (payload.email == "" || payload.name == "" || payload.phone == "" || payload.cnumber == "" || payload.date == "" || payload.office == "") {
            throw("All input fields are required")
        }

        if (!payload.agreement) {
            throw("You must agree to the terms of service to proceed")
        }

        if (payload.office == "") {
            throw("Kindly select a valid Office of Payment")
        }

        if (!Number.isInteger(payload.cnumber)) {
            throw("Your C-Number format should be only numbers")
        }

        var phoneno = /^\d{11}$/;
        if(!payload.phone.match(phoneno)) {
            throw("Your phone number should be 11 numbers. Kindly check again!")
        }

        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(!payload.email.match(mailformat)) {
            throw("Your email address is incorrect. Kindly check again!")
        }

        makePayment(payload)

    } catch (e) {
        console.log(e)
        showAlertMessage(position, e)
    }
}

export const verify2 = () => {
    showAlert2(position)

    const form = document.querySelector('.service-verify form')
    const inputs = form.querySelectorAll('input')
    const office = form.querySelector('select')


    let [name, email, phone, manifest, cnumber, date, agreement] = inputs

    const payload = {
        email: email.value,
        name: name.value,
        phone: phone.value,
        manifest: manifest.value,
        cnumber: parseInt(cnumber.value),
        date: date.value,
        office: office.value,
        agreement: agreement.checked
    }

    console.log("PAYLOAD: ", payload)


    try {
        if (payload.email == "" || payload.name == "" || payload.phone == "" || payload.cnumber == "" || payload.date == "" || payload.office == "") {
            throw("All input fields are required")
        }

        if (!payload.agreement) {
            throw("You must agree to the terms of service to proceed")
        }

        if (payload.office == "") {
            throw("Kindly select a valid Office of Payment")
        }

        if (!Number.isInteger(payload.cnumber)) {
            throw("Your C-Number format should be only numbers")
        }

        var phoneno = /^\d{11}$/;
        if(!payload.phone.match(phoneno)) {
            throw("Your phone number should be 11 numbers. Kindly check again!")
        }

        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(!payload.email.match(mailformat)) {
            throw("Your email address is incorrect. Kindly check again!")
        }

        makePayment(payload)

    } catch (e) {
        console.log(e)
        showAlertMessage(position, e)
    }
}


const makePayment = (payload) => {
    var handler = PaystackPop.setup({
        key: 'pk_live_947e4acc9e3f12e21ba3c83377ea6b7cf028b84a',
        // key: 'pk_test_04175d0863416647223b508421dcad58398c5bee',
        email: payload.email,
        amount: 300000,
        currency: "NGN",
        metadata: {
           custom_fields: [
              {
                  display_name: "Mobile Number",
                  variable_name: "mobile_number",
                  value: payload.phone
              },
              {
                display_name: "Name",
                variable_name: "name",
                value: payload.name
              }
           ]
        },
        callback: function(response){
            payload.reference = response.reference
            finalise(payload)
        },
        onClose: function(){
            showAlertMessage(position, 'window closed')
        }
      });
      handler.openIframe();
}

const finalise = (payload) => {
    axios.post(`${apiBaseURL}/contact/verify`, payload)
        .then((res) => {
            showAlertMessageAndClose(position, 'Message sent Succesfully')
        })
        .catch((err) => {
            showAlertMessage(position, err)
      })
}

